.buyPop {
    >div {
      max-width: 389px;
    }
  
    h2.price {
      font-size: 55px;
      background: #1CCAF1;
      background: linear-gradient(to right, #1CCAF1 0%, #4175F7 57%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 3px 1px 1px #1CCAF1;
  
    }
  }